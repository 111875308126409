import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Чат хэрэглүүрээр хэрхэн хэлцэл хийх вэ?",
  "description": null,
  "author": "OTC help",
  "category": "chat",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Чат хэрэглүүрээр хэрхэн хэлцэл хийх вэ?`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэсний `}<strong parentName="p">{`[Chat]`}</strong>{` хэсэгт `}<strong parentName="p">{`[Search Users]`}</strong>{` хайлтын хэсгээр харилцах оролцогчийн нэрийг хайх эсвэл жагсаалтаас сонгоно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Чатны цонхны баруун дээд булан дахь `}<strong parentName="p">{`[Propose a Deal]`}</strong>{` товчийг дарснаар хэлцэл хийх санал илгээх цонх нээгдэнэ. `}<strong parentName="p">{`[Propose a Deal]`}</strong>{` хэсэг нь зөвхөн ББЗЗ-н арилжааны цагаар буюу ажлын өдрийн 10:00 - 16:00 цагийн хооронд идэвхжих болохыг анхаарна уу.
Тус хэсэгт илгээх саналын мэдээллийг дараах байдлаар оруулна: - `}<strong parentName="p">{`[Security]`}</strong>{` хэсэгт дарж ББЗЗ-д арилжаалагдаж буй үнэт цаасны жагсаалтаас хэлцэл хийх үнэт цаасыг сонгоно - `}<strong parentName="p">{`[Side]`}</strong>{` хэсэгт санал илгээх `}{`[Buy]`}{` буюу авах `}{`[Sell]`}{` буюу зарах талаа сонгоно - `}<strong parentName="p">{`[Price]`}</strong>{` хэсэгт санал тавих нэгжийн үнийн саналаа оруулна - `}<strong parentName="p">{`[Quantity]`}</strong>{` хэсэгт хэлцэл хийх үнэт цаасны тоо ширхгийг оруулна - `}<strong parentName="p">{`[Trade date Option]`}</strong>{` хэсэгт Т+0 (зөвхөн тухайн өдрийн 10:00-11:45 цагийн хооронд хийгдэх хэлцэл) буюу тухайн өдөртөө төлбөр тооцоо хийгдэх, T+1 буюу дараа өдөр төлбөр тооцоог хийх гэсэн сонголтоос сонгоно. - `}<strong parentName="p">{`[Message]`}</strong>{` хэсэгт хэлцэлтэй холбоотой нэмэлт тайлбар оруулж болно.
Мэдээллүүдийг оруулан `}<strong parentName="p">{`[Create]`}</strong>{` товч дарж саналыг харилцаж буй талд илгээнэ. Илгээсэн саналын төлөвийг баруун доод буланд харах боломжтой`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Санал хүлээн авсан оролцогч талд чат хэсэгт тус саналын нөхцөлүүд болон `}<strong parentName="p">{`[Decline]`}</strong>{` буюу татгалзах, `}<strong parentName="p">{`[Counteroffer]`}</strong>{` буюу нөхцөлийг өөрчлөн эргүүлэн санал тавих, `}<strong parentName="p">{`[Approve]`}</strong>{` буюу зөвшөөрөх гэсэн сонголтууд ирнэ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналыг татгалзах бол `}<strong parentName="p">{`[Decline]`}</strong>{` товчийг дарснаар харилцагч талд татгалзан тухай мэдэгдэл очиж, тухайн санал оролцогчийн `}<strong parentName="p">{`[My Deals]`}{`-`}{`[Ongoing Deals]`}</strong>{` хэсгээс устах болно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналын нөхцөлийг өөрчлөн санал тавих бол `}<strong parentName="p">{`[Counteroffer]`}</strong>{` товчийг дарж хэлцлийн саналд өөрчлөлт оруулан `}<strong parentName="p">{`[`}{`[Create]`}</strong>{` товч дарж саналыг эсрэг талд илгээнэ.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналыг хүлээн авах бол `}<strong parentName="p">{`[Approve]`}</strong>{` товчийг дарж шаардлагатай мэдээллийг оруулна:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Client ID]`}</strong>{` хэсэгт хүлээн авч буй харилцагчийн бүртгэлийн дугаарыг`}</li>
          <li parentName="ul"><strong parentName="li">{`[Broker Fee]`}</strong>{` хэсэгт брокерын төлбөрийн хэлбэр (тогтмол дүн/хувь) болон дүнг,`}</li>
          <li parentName="ul"><strong parentName="li">{`[Custodian]`}</strong>{` хэсэгт тухайн үнэт цаасны бүртгэл, төлбөр тооцоо болон хадгалалт хийгдэж буй кастодианыг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Payment type]`}</strong>{` хэсэгт үнэт цаасны төлбөр тооцоонийй хэлбэрийг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Fee info]`}</strong>{` хэсэгт тухайн хэлцлийн төлбөр, хураамжийг тооцон хураангуйлан харуулах бол `}<strong parentName="li">{`[Amount info]`}</strong>{` хэсгээс хэлцлийн нийт хэмжээ болон дүнг харах боломжтой`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх хэлцлийн мэдээллээ нягталж шалган `}<strong parentName="p">{`[Submit]`}</strong>{` товчийг дарж хэлцэл хийх хүсэлтийг илгээнэ.`}</p>
    <p>{`Илгээсэн хэлцэл хийх хүсэлтийн мэдээллийг бүхэлд нь арилгах бол `}<strong parentName="p">{`[Reset]`}</strong>{` товчийг дарж шинээр оруулна.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Амжилттай илгээсэн хэлцлийн хүсэлтийг Харилцагч тал мөн ижил `}<strong parentName="li">{`[Approve]`}</strong>{` хийснээр хэлцэл нь `}<strong parentName="li">{`[My Deals]`}{`-`}{`[Settlements]`}</strong>{` хэсэгт шилжинэ.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      